<template>
	<v-row>
		<v-dialog v-model="dialogCadastro" persistent max-width="450px">
			<v-card>
				<v-card-title>Novo registro</v-card-title>
				<v-divider />
				<v-card-text>
					<v-row class="mt-3">
						<v-col cols="12" class="mb-n7">
							<v-autocomplete
								:items="dados.func"
								item-text="filial"
								item-value="chave"
								label="Filial"
								outlined
								dense
								v-model="novo.idempresa"
							></v-autocomplete>
						</v-col>
						<v-col cols="12" class="mb-n7">
							<v-autocomplete
								:items="cargos.cargos"
								item-text="cargo"
								item-value="idcargo"
								label="Cargo"
								outlined
								dense
								v-model="novo.idcargo"
							></v-autocomplete>
						</v-col>
						<v-col cols="12" class="mb-n7">
							<v-text-field label="Meta Turnover" type="number" outlined dense v-model="novo.meta"></v-text-field>
						</v-col>
					</v-row>
				</v-card-text>
				<v-divider />
				<v-card-actions class="pa-3">
					<v-spacer />
					<v-btn color="primary" text @click="dialogCadastro = false">Fechar</v-btn>
					<v-btn
						:disabled="carregando"
						:loading="carregando"
						color="primary"
						elevation="0"
						@click="definirMetaTurnover(novo.idempresa.split('-')[0], novo.idempresa.split('-')[1], novo.idcargo, novo.meta)"
					>Salvar</v-btn>
				</v-card-actions>
			</v-card>
		</v-dialog>
		<v-dialog v-model="dialogErro.status" persistent>
			<CardDialog :dialog="dialogErro" />
		</v-dialog>
		<DialogDashboards :dialog="dialog" />
		<v-col cols="12" md="12">
			<v-card>
				<v-card-text>
					<v-form>
						<v-row class="mb-n10">
							<v-col class="pr-1 pl-1 pl-lg-3">
								<InputDatePicker
									type="month"
									:disabled="carregando"
									v-model="busca.dtmes"
									label="Data"
									:outlined="true"
									:dense="true"
								/>
							</v-col>
							<v-col class="px-1 pl-md-1 pr-lg-3 d-flex">
								<v-autocomplete
									:items="filiaisFiltro"
									clearable
									item-text="filial"
									item-value="idfilial"
									label="Filial"
									outlined
									dense
									:disabled="carregando"
									v-model="busca.idfiliais"
									multiple
								>
									<template v-slot:selection="{ item, index }">
										<template v-if="index === 0">{{ item.filial }}</template>
										<span
											v-if="index === 1"
											class="grey--text text-caption ml-1"
										>(+{{ busca.idfiliais.length - 1 }})</span>
									</template>
								</v-autocomplete>
								<v-btn
									:disabled="carregando"
									class="ml-2 mt-1"
									color="primary"
									elevation="0"
									fab
									x-small
									@click="listar()"
								>
									<v-icon>mdi-magnify</v-icon>
								</v-btn>
							</v-col>
						</v-row>
					</v-form>
				</v-card-text>
			</v-card>
		</v-col>
		<v-col cols="12" md="12">
			<v-row class="pa-2">
				<v-col class="pa-1">
					<CardDashboard :loading="carregando" title="Meta de turnover">
						<template
							v-if="dados.func"
						>{{((dados.func.map(v => {return v.metaturnover}).reduce((a, v) => a + v)) / (dados.func.map(v => {return v.metaturnover}).length)).toFixed(2)}}%</template>
						<template v-else>0%</template>
					</CardDashboard>
				</v-col>
				<v-col class="pa-1">
					<CardDashboard :loading="carregando" title="Acumulado turnover (últ. 12 meses)">
						<template
							v-if="dados.func"
						>{{((dados.func.map(v => {return v.turnoverano}).reduce((a, v) => a + v)) / (dados.func.map(v => {return v.turnoverano}).length)).toFixed(2)}}%</template>
						<template v-else>0%</template>
					</CardDashboard>
				</v-col>
				<v-col class="pa-1">
					<CardDashboard :loading="carregando" title="Turnover mês">
						<template
							v-if="dados.func"
						>{{((dados.func.map(v => {return v.turnover}).reduce((a, v) => a + v)) / (dados.func.map(v => {return v.turnover}).length)).toFixed(2)}}%</template>
						<template v-else>0%</template>
					</CardDashboard>
				</v-col>
				<v-col class="pa-1">
					<CardDashboard :loading="carregando" title="Colaboradores ativos">
						<template
							v-if="dados.func"
						>{{dados.func.map(v => {return v.totalativos}).reduce((a, v) => a + v)}}</template>
						<template v-else>0</template>
					</CardDashboard>
				</v-col>
				<v-col class="pa-1">
					<CardDashboard :loading="carregando" title="Admissões mês">
						<template
							v-if="dados.func"
						>{{dados.func.map(v => {return v.admitido}).reduce((a, v) => a + v)}}</template>
						<template v-else>0</template>
					</CardDashboard>
				</v-col>
				<v-col class="pa-1">
					<CardDashboard :loading="carregando" title="Demissões mês">
						<template
							v-if="dados.func"
						>{{dados.func.map(v => {return v.demitido}).reduce((a, v) => a + v)}}</template>
						<template v-else>0</template>
					</CardDashboard>
				</v-col>
			</v-row>
		</v-col>
		<v-col cols="12">
			<v-card dark color="primary">
				<v-progress-linear indeterminate absolute :active="carregando" />
				<v-card-title class="d-flex col-12 text-truncate">Evolução turnover nos últimos 12 meses</v-card-title>
				<v-card-text v-if="carregando">
					<v-skeleton-loader class="mx-auto" width="100%" height="300" type="image, image"></v-skeleton-loader>
				</v-card-text>
				<v-card-text v-else>
					<v-simple-table light dense class="rounded-0" v-if="dados.evolucao">
						<thead>
							<tr class="blue-grey lighten-4">
								<th>Filial</th>
								<th
									v-for="(mes, i) in dados.func[0].turnovermeses.map(v => { return `${v.mes.split('-')[1]}/${v.mes.split('-')[0]}` }).reverse()"
									:key="i"
								>{{mes}}</th>
							</tr>
						</thead>
						<tbody>
							<tr v-for="(dado,i) in dados.evolucao" :key="i">
								<td class="text-truncate font-weight-bold">{{formataNome(dado.name)}}</td>
								<td
									class="text-end text-truncate font-weight-bold"
									v-for="(d, i) in dado.data"
									:key="i"
									:class="verificaCor(d)"
								>{{d}}%</td>
							</tr>
						</tbody>
					</v-simple-table>
					<span v-else>Nenhum registro encontrado</span>
				</v-card-text>
			</v-card>
		</v-col>
		<template v-if="dados.func">
			<v-col
				cols="12"
				:md="dados.func.length > 0 && dados.func.length < 4 ? '6' : '12'"
				v-if="dados.func.length != 1"
			>
				<v-card>
					<v-progress-linear indeterminate absolute :active="carregando" />
					<v-card-title
						class="d-inline-block col-12 text-truncate"
					>Turnover por filial (turnover atual x ano anterior)</v-card-title>
					<v-card-text v-if="carregando">
						<v-skeleton-loader class="mx-auto" width="100%" height="300" type="image, image"></v-skeleton-loader>
					</v-card-text>
					<v-card-text v-else>
						<GraficoLineColumnDashboard
							v-if="dados.func"
							:dataSeries="[
                            {
                                name: 'Turnover atual', 
                                type: 'column', 
                                data: dados.func.map(v => { return v.turnover.toFixed(2)})
                            },
                            {
                                name: 'Ano anterior', 
                                type: 'line', 
                                data: dados.func.map(v => { return v.turnoverano.toFixed(2)})
                            },
                        ]"
							:labels="dados.func.map(v => { return v.filial})"
							:titlesY="[
                            { title: { text: 'Turnover atual' }, labels: {formatter: function (val) { return val.toFixed(2) + '%' }} },
                            { opposite: true, title: { text: 'Ano anterior' }, labels: {formatter: function (val) { return val.toFixed(2) + '%' }} }, 
                        ]"
							:tooltipsY="[
                            { enabled: true, formatter: function (val) { return val.toFixed(2) + '%' } },
                            { enabled: true, formatter: function (val) { return val.toFixed(2) + '%' } },
                        ]"
							height="300"
							:porcentagem="true"
							:money="false"
						/>
						<span v-else>Nenhum registro encontrado</span>
					</v-card-text>
				</v-card>
			</v-col>
			<v-col
				cols="12"
				:md="dados.func.length > 0 && dados.func.length < 4 ? '6' : '12'"
				v-if="dados.func.length != 1"
			>
				<v-card>
					<v-progress-linear indeterminate absolute :active="carregando" />
					<v-card-title class="d-inline-block col-12 text-truncate">Turnover x meta por filial</v-card-title>
					<v-card-text v-if="carregando">
						<v-skeleton-loader class="mx-auto" width="100%" height="300" type="image, image"></v-skeleton-loader>
					</v-card-text>
					<v-card-text v-else>
						<GraficoLineColumnDashboard
							v-if="dados.func"
							:dataSeries="[
                            {
                                name: 'Turnover', 
                                type: 'column', 
                                data: dados.func.map(v => { return v.turnover.toFixed(2)})
                            },
                            {
                                name: 'Meta', 
                                type: 'line', 
                                data: dados.func.map(v => { return v.metaturnover.toFixed(2)})
                            },
                        ]"
							:labels="dados.func.map(v => { return v.filial})"
							:titlesY="[
                            { title: { text: 'Turnover' }, labels: {formatter: function (val) { return val.toFixed(2) + '%' }} },
                            { opposite: true, title: { text: 'Meta' }, labels: {formatter: function (val) { return val.toFixed(2) + '%' }} }, 
                        ]"
							:tooltipsY="[
                            { enabled: true, formatter: function (val) { return val.toFixed(2) + '%' } },
                            { enabled: true, formatter: function (val) { return val.toFixed(2) + '%' } },
                        ]"
							height="300"
							:porcentagem="true"
							:money="false"
						/>
						<span v-else>Nenhum registro encontrado</span>
					</v-card-text>
				</v-card>
			</v-col>
		</template>
		<template v-else>
			<v-col cols="12">
				<v-card>
					<v-progress-linear indeterminate absolute :active="carregando" />
					<v-card-title
						class="d-inline-block col-12 text-truncate"
					>Turnover por filial (turnover atual x ano anterior)</v-card-title>
					<v-card-text v-if="carregando">
						<v-skeleton-loader class="mx-auto" width="100%" height="300" type="image, image"></v-skeleton-loader>
					</v-card-text>
				</v-card>
			</v-col>
			<v-col cols="12">
				<v-card>
					<v-progress-linear indeterminate absolute :active="carregando" />
					<v-card-title class="d-inline-block col-12 text-truncate">Turnover x meta por filial</v-card-title>
					<v-card-text v-if="carregando">
						<v-skeleton-loader class="mx-auto" width="100%" height="300" type="image, image"></v-skeleton-loader>
					</v-card-text>
				</v-card>
			</v-col>
		</template>
		<v-col cols="12" md="6">
			<v-card height="100%">
				<v-progress-linear indeterminate absolute :active="carregando" />
				<v-card-title v-if="usuario.idgrupo != 7" class="d-flex col-12 text-truncate">
					* Motivos de desligamento (colaborador)
					<v-spacer />
					<v-btn
						:disabled="carregando"
						class="px-2 mb-3"
						color="primary"
						elevation="0"
						small
						@click="abrirDialog(
                            [
                                {id: 1, colaborador: 'João da Silva', motivo: 'Pessoal', datadesligamento: '18/03/2022'},
                                {id: 1, colaborador: 'João da Silva', motivo: 'Pessoal', datadesligamento: '18/03/2022'},
                                {id: 1, colaborador: 'João da Silva', motivo: 'Pessoal', datadesligamento: '18/03/2022'},
                            ], 
							{
								id: {nome: 'ID', tipo: 'texto'},
								colaborador: {nome: 'Colaborador', tipo: 'texto'},
								motivo: {nome: 'Motivo Desligamento', tipo: 'texto'},
								datadesligamento: {nome: 'Data', tipo: 'texto'},
							},
                            'Motivos de desligamento (colaborador)'
                        )"
					>
						<v-icon size="18">mdi-magnify-plus-outline</v-icon>Ver mais
					</v-btn>
				</v-card-title>
				<v-card-text v-if="carregando">
					<v-skeleton-loader class="mx-auto" width="100%" height="300" type="image, image"></v-skeleton-loader>
				</v-card-text>
				<v-card-text v-else>
					<GraficoPieDashboard
						v-if="true"
						:dataSeries="[35, 25, 42]"
						:labels="['Pessoal', 'Não informou', 'Outra empresa']"
						legend="right"
						height="300"
					/>
					<span v-else>Nenhum registro encontrado</span>
				</v-card-text>
			</v-card>
		</v-col>
		<v-col cols="12" md="6">
			<v-card height="100%">
				<v-progress-linear indeterminate absolute :active="carregando" />
				<v-card-title v-if="usuario.idgrupo != 7" class="d-flex col-12 text-truncate">
					* Motivos de desligamento (empresa)
					<v-spacer />
					<v-btn
						:disabled="carregando"
						class="px-2 mb-3"
						color="primary"
						elevation="0"
						small
						@click="abrirDialog(
                            [
                                {id: 1, colaborador: 'João da Silva', motivo: 'Justa causa', datadesligamento: '18/03/2022'},
                                {id: 1, colaborador: 'João da Silva', motivo: 'Justa causa', datadesligamento: '18/03/2022'},
                                {id: 1, colaborador: 'João da Silva', motivo: 'Justa causa', datadesligamento: '18/03/2022'},
                            ], 
							{
								id: {nome: 'ID', tipo: 'texto'},
								colaborador: {nome: 'Colaborador', tipo: 'texto'},
								motivo: {nome: 'Motivo Desligamento', tipo: 'texto'},
								datadesligamento: {nome: 'Data', tipo: 'texto'},
							},
                            'Motivos de desligamento (empresa)'
                        )"
					>
						<v-icon size="18">mdi-magnify-plus-outline</v-icon>Ver mais
					</v-btn>
				</v-card-title>
				<v-card-text v-if="carregando">
					<v-skeleton-loader class="mx-auto" width="100%" height="300" type="image, image"></v-skeleton-loader>
				</v-card-text>
				<v-card-text v-else>
					<GraficoPieDashboard
						v-if="true"
						:dataSeries="[35, 25, 42]"
						:labels="['Justa causa', 'Atraso', 'Meta']"
						legend="right"
						height="300"
					/>
					<span v-else>Nenhum registro encontrado</span>
				</v-card-text>
			</v-card>
		</v-col>
		<v-col cols="12">
			<v-card>
				<v-card-title>
					Total de colaboradores admitidos e demitidos por filial e cargo (falta update de meta)
					<v-spacer />
					<v-btn elevation="0" color="primary" fab small @click="dialogCadastro = true, novo = {}">
						<v-icon>mdi-plus</v-icon>
					</v-btn>
				</v-card-title>
				<v-expansion-panels v-if="dados.func" flat>
					<v-expansion-panel
						v-for="(filial, i) in dados.func"
						:key="i"
						active-class="primary white--text rounded-0"
					>
						<v-divider />
						<v-expansion-panel-header class="py-2" v-slot="{ open }" hide-actions>
							<v-row no-gutters align="center">
								<v-col cols="4" class="text-h6 text-truncate">{{formataNome(filial.filial)}}</v-col>
								<v-col cols="8" class="text--secondary">
									<v-fade-transition hide-on-leave>
										<v-row v-if="!open" no-gutters style="width: 100%">
											<v-col class="text-center black--text font-weight-bold text-truncate">
												Total de funcionários
												<br />
												<v-chip color="primary" small class="mt-1 px-2 px-md-5">{{filial.totalativos}}</v-chip>
											</v-col>
											<v-col class="text-center black--text font-weight-bold text-truncate">
												Admitidos
												<br />
												<v-chip color="green darken-1" dark small class="mt-1 px-2 px-md-5">{{filial.admitido}}</v-chip>
											</v-col>
											<v-col class="text-center black--text font-weight-bold text-truncate">
												Demitidos
												<br />
												<v-chip color="red darken-3" dark small class="mt-1 px-2 px-md-5">{{filial.demitido}}</v-chip>
											</v-col>
											<v-col class="text-center black--text font-weight-bold text-truncate">
												Turnover (%)
												<br />
												<v-chip color="primary" small class="mt-1 px-2 px-md-4">
													<template v-if="filial.turnover">{{filial.turnover.toFixed(2)}} %</template>
													<template v-else>0.00 %</template>
												</v-chip>
											</v-col>
											<v-col class="text-center black--text font-weight-bold text-truncate">
												Meta turnover (%)
												<br />
												<v-chip color="primary" small class="mt-1 px-2 px-md-4">
													<template v-if="filial.metaturnover">{{filial.metaturnover.toFixed(2)}} %</template>
													<template v-else>0.00 %</template>
												</v-chip>
											</v-col>
										</v-row>
									</v-fade-transition>
								</v-col>
							</v-row>
						</v-expansion-panel-header>
						<v-expansion-panel-content>
							<v-simple-table dense class="rounded-0">
								<thead>
									<tr class="blue-grey lighten-4">
										<th>Cargo</th>
										<th class="text-center">Total de funcionários</th>
										<th class="text-center">Admitidos</th>
										<th class="text-center">Demitidos</th>
										<th class="text-center">Turnover (%)</th>
										<th class="text-center">Meta turnover (%)</th>
									</tr>
								</thead>
								<tbody>
									<tr v-for="(cargo, i) in filial.cargostotalcargo" :key="i">
										<td>{{cargo.cargo}}</td>
										<td class="text-center">{{cargo.totalativos}}</td>
										<td class="text-center">{{cargo.admitido}}</td>
										<td class="text-center">{{cargo.demitido}}</td>
										<td class="text-center">
											<template v-if="cargo.turnover">{{cargo.turnover.toFixed(2)}} %</template>
											<template v-else>0.00 %</template>
										</td>
										<td class="text-center">
											<v-text-field
												:disabled="carregando"
												class="mx-auto py-0 mb-n3"
												reverse
												prefix="%"
												style="width: 100px;"
												type="number"
												dense
												v-model="cargo.metaturnover"
											></v-text-field>
										</td>
									</tr>
								</tbody>
								<tfoot>
									<tr class="blue-grey lighten-4">
										<td class="font-weight-bold text-truncate">Total por filial</td>
										<td class="text-center font-weight-bold text-truncate">{{filial.totalativos}}</td>
										<td class="text-center font-weight-bold text-truncate">{{filial.admitido}}</td>
										<td class="text-center font-weight-bold text-truncate">{{filial.demitido}}</td>
										<td class="text-center font-weight-bold text-truncate">
											<template v-if="filial.turnover">{{filial.turnover.toFixed(2)}} %</template>
											<template v-else>0.00 %</template>
										</td>
										<td class="text-center font-weight-bold text-truncate">
											<template v-if="filial.metaturnover">{{filial.metaturnover.toFixed(2)}} %</template>
											<template v-else>0.00 %</template>
										</td>
									</tr>
								</tfoot>
							</v-simple-table>
						</v-expansion-panel-content>
					</v-expansion-panel>
					<v-expansion-panel active-class="primary white--text rounded-0">
						<v-divider />
						<v-expansion-panel-header class="py-2" v-slot="{ open }" hide-actions>
							<v-row no-gutters align="center">
								<v-col cols="4" class="text-h6">Total por cargos</v-col>
								<v-col cols="8" class="text--secondary">
									<v-fade-transition hide-on-leave>
										<v-row v-if="!open" no-gutters style="width: 100%">
											<v-col class="text-center black--text font-weight-bold text-truncate">
												Total de funcionários
												<br />
												<v-chip
													v-if="dados.func"
													color="primary"
													small
													class="mt-1 px-2 px-md-5"
												>{{dados.func.map(v => {return v.totalativos}).reduce((a, v) => a + v)}}</v-chip>
											</v-col>
											<v-col class="text-center black--text font-weight-bold text-truncate">
												Admitidos
												<br />
												<v-chip
													v-if="dados.func"
													color="green darken-1"
													dark
													small
													class="mt-1 px-2 px-md-5"
												>{{dados.func.map(v => {return v.admitido}).reduce((a, v) => a + v)}}</v-chip>
											</v-col>
											<v-col class="text-center black--text font-weight-bold text-truncate">
												Demitidos
												<br />
												<v-chip
													v-if="dados.func"
													color="red darken-3"
													dark
													small
													class="mt-1 px-2 px-md-5"
												>{{dados.func.map(v => {return v.demitido}).reduce((a, v) => a + v)}}</v-chip>
											</v-col>
											<v-col class="text-center black--text font-weight-bold text-truncate">
												Turnover (%)
												<br />
												<v-chip
													v-if="dados.func"
													color="primary"
													small
													class="mt-1 px-2 px-md-4"
												>{{((dados.func.map(v => {return v.turnover}).reduce((a, v) => a + v)) / (dados.func.map(v => {return v.turnover}).length)).toFixed(2)}} %</v-chip>
											</v-col>
											<v-col class="text-center black--text font-weight-bold text-truncate">
												Meta turnover (%)
												<br />
												<v-chip
													v-if="dados.func"
													color="primary"
													small
													class="mt-1 px-2 px-md-4"
												>{{((dados.func.map(v => {return v.metaturnover}).reduce((a, v) => a + v)) / (dados.func.map(v => {return v.metaturnover}).length)).toFixed(2)}} %</v-chip>
											</v-col>
										</v-row>
									</v-fade-transition>
								</v-col>
							</v-row>
						</v-expansion-panel-header>
						<v-expansion-panel-content>
							<v-simple-table dense class="rounded-0">
								<thead>
									<tr class="blue-grey lighten-4">
										<th>Cargo</th>
										<th class="text-center">Total de funcionários</th>
										<th class="text-center">Admitidos</th>
										<th class="text-center">Demitidos</th>
										<th class="text-center">Turnover (%)</th>
										<th class="text-center">Meta turnover (%)</th>
									</tr>
								</thead>
								<tbody>
									<tr v-for="(total, i) in dados.tot" :key="i">
										<td>{{total.cargo}}</td>
										<td class="text-center">{{total.totalativos}}</td>
										<td class="text-center">{{total.admitido}}</td>
										<td class="text-center">{{total.demitido}}</td>
										<td class="text-center">
											<template v-if="total.turnover">{{total.turnover.toFixed(2)}} %</template>
											<template v-else>0.00 %</template>
										</td>
										<td class="text-center">
											<template v-if="total.metaturnover">{{total.metaturnover.toFixed(2)}} %</template>
											<template v-else>0.00 %</template>
										</td>
									</tr>
								</tbody>
								<tfoot>
									<tr v-if="dados.func" class="blue-grey lighten-4">
										<td class="font-weight-bold text-truncate">Total geral</td>
										<td
											class="text-center font-weight-bold text-truncate"
										>{{dados.func.map(v => {return v.totalativos}).reduce((a, v) => a + v)}}</td>
										<td
											class="text-center font-weight-bold text-truncate"
										>{{dados.func.map(v => {return v.admitido}).reduce((a, v) => a + v)}}</td>
										<td
											class="text-center font-weight-bold text-truncate"
										>{{dados.func.map(v => {return v.demitido}).reduce((a, v) => a + v)}}</td>
										<td
											class="text-center font-weight-bold text-truncate"
										>{{((dados.func.map(v => {return v.turnover}).reduce((a, v) => a + v)) / (dados.func.map(v => {return v.turnover}).length)).toFixed(2)}} %</td>
										<td
											class="text-center font-weight-bold text-truncate"
										>{{((dados.func.map(v => {return v.metaturnover}).reduce((a, v) => a + v)) / (dados.func.map(v => {return v.metaturnover}).length)).toFixed(2)}} %</td>
									</tr>
								</tfoot>
							</v-simple-table>
						</v-expansion-panel-content>
					</v-expansion-panel>
				</v-expansion-panels>
			</v-card>
		</v-col>
	</v-row>
</template>

<script>
import { mapState } from "vuex";
import axios from "axios";
import mixinFilial from "../../util/mixinFilial";
import InputDatePicker from "../Widgets/InputDatePicker";
import CardDashboard from "../Widgets/CardDashboard";
import GraficoPieDashboard from "../Widgets/GraficoPieDashboard";
import GraficoLineColumnDashboard from "../Widgets/GraficoLineColumnDashboard";
import DialogDashboards from "../Widgets/DialogDashboards";
import CardDialog from "../Widgets/CardDialog";

export default {
	name: "DepartamentoPessoalDashboardTurnover",
	mixins: [mixinFilial],
	components: {
		GraficoLineColumnDashboard,
		GraficoPieDashboard,
		CardDashboard,
		InputDatePicker,
		DialogDashboards,
		CardDialog,
	},
	data: () => ({
		carregando: false,
		novo: {},
		dialog: { status: false, header: {}, dados: [] },
		dialogCadastro: false,
		dialogErro: {
			status: false,
			title: "",
			msg: "",
			icon: "",
			iconColor: "",
		},
		busca: {
			dtmes: `${new Date().getFullYear()}-${
				(new Date(
					new Date().getFullYear(),
					new Date().getMonth(),
					0
				).getMonth() < 10
					? "0"
					: "") + new Date().getMonth()
			}`, // +1
			idfiliais: [],
		},
		dados: {},
		cargos: {},
	}),
	computed: {
		...mapState(["backendUrl", "pgLimit", "usuario"]),
		filiaisFiltro() {
			if (this.filiais.lista) {
				return this.filiais.lista.filter((v) => {
					return (
						v.idfilial != 6 &&
						v.idfilial != 12 &&
						v.idfilial != 25 &&
						v.idfilial != 29 &&
						v.idfilial != 33 &&
						v.idfilial != 37
					);
				});
			} else {
				return [];
			}
		},
	},
	methods: {
		listar() {
			let idfiliais = null;
			if (this.busca.idfiliais.length > 0) {
				idfiliais = this.busca.idfiliais.join(",");
			}
			this.dados = {};
			this.carregando = true;
			return axios
				.post(`${this.backendUrl}dp/turnover`, {
					idfilial: idfiliais || null,
					data: this.busca.dtmes,
				})
				.then((res) => {
					let evolucaoMeses = [];
					res.data.func.forEach((v) => {
						v.chave = `${v.idempresa}-${v.idfilial}`;
						let mes = {};
						mes.name = v.filial;
						mes.data = v.turnovermeses
							.map((v) => {
								return v.valor.toFixed(2);
							})
							.reverse();
						evolucaoMeses.push(mes);
					});
					this.dados = res.data;
					this.dados.evolucao = evolucaoMeses;
					this.carregando = false;
				})
				.catch(() => {
					this.carregando = false;
				});
		},
		listarCargos() {
			return axios.post(`${this.backendUrl}dp/cargos`).then((res) => {
				this.cargos = res.data;
			});
		},
		abrirDialog(dados, header, title) {
			this.dialog.status = true;
			this.dialog.dados = dados;
			this.dialog.header = header;
			this.dialog.title = title;
		},
		definirMetaTurnover(idempresa, idfilial, idcargo, meta) {
			this.carregando = true;
			return axios
				.post(`${this.backendUrl}dp/cargo/cadastrar/meta`, {
					numEmprSenior: idempresa,
					idfilial: idfilial,
					idcargo: idcargo,
					meta: parseInt(meta),
				})
				.then((res) => {
					this.carregando = false;
					this.dialogCadastro = false;
					if (res.data) {
						this.dialogErro.title = "Cadastro efetuado";
						this.dialogErro.msg =
							"Cadastro de meta de turnover realizado com sucesso!";
						this.dialogErro.icon = "mdi-check-circle-outline";
						this.dialogErro.iconColor = "success";
						this.dialogErro.status = true;
						this.listar();
					} else {
						this.dialogErro.title = "Erro";
						this.dialogErro.msg =
							"Não foi possível alterar a meta de turnover!";
						this.dialogErro.icon = "mdi-alert-circle-outline";
						this.dialogErro.iconColor = "red";
						this.dialogErro.status = true;
					}
				})
				.catch(() => {
					this.dialogCadastro = false;
					this.carregando = false;
					this.dialogErro.title = "Erro";
					this.dialogErro.msg =
						"Não foi possível alterar a meta de turnover!";
					this.dialogErro.icon = "mdi-alert-circle-outline";
					this.dialogErro.iconColor = "red";
					this.dialogErro.status = true;
				});
		},
		formataNome(nome) {
			if (nome == "CASTELO MATERIAIS DE CONSTRUÇÃO") {
				return "Castelo";
			} else if (nome == "Logística São Mateus do Sul") {
				return "São Mateus do Sul CD";
			} else if (nome == "Apucarana - CD") {
				return "Apucarana CD";
			} else if (nome == "MARINGA - CD") {
				return "Maringá CD";
			} else {
				return nome;
			}
		},
		verificaCor(valor) {
			if (valor <= 3) {
				return "green--text";
			} else if (valor > 3 && valor < 10) {
				return "orange--text";
			} else {
				return "red--text";
			}
		},
		async init() {
			await this.listar();
			await this.listarCargos();
		},
	},
	watch: {
		"busca.dtmes": function (newValue, oldValue) {
			let mesAtual = `${new Date().getFullYear()}-${
				(new Date(
					new Date().getFullYear(),
					new Date().getMonth() + 1,
					0
				).getMonth() +
					1 <
				10
					? "0"
					: "") +
				(new Date().getMonth() + 1)
			}`;
			if (this.busca.dtmes >= mesAtual) {
				this.dialogErro.title = "Erro";
				this.dialogErro.msg =
					"A data seleciona deve ser anterior ao mês atual!";
				this.dialogErro.icon = "mdi-alert-circle-outline";
				this.dialogErro.iconColor = "red";
				this.dialogErro.status = true;
				this.busca.dtmes = oldValue;
			} else {
				this.listar();
			}
		},
	},
	created() {
		this.init();
	},
};
</script>

<style scoped>
</style>